import * as React from "react"
import ExternalLink from "./externallink"
import "../scss/components/socialicons.scss"

const SocialIcons= () => (
    <ul id="social_icons" className="list-unstyle">
        <li>
            <ExternalLink link="https://instagram.com/msminifarm/">
                Instagram
            </ExternalLink>
        </li>
        <li>
            <ExternalLink link="https://www.facebook.com/msminifarm">
                Facebook
            </ExternalLink>
        </li>
    </ul>
)

export default SocialIcons
